/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file

==========================================================================================*/

import Vue from 'vue';
import App from './App.vue';

// Vuesax Component Framework
import Vuesax from 'vuesax';
import 'material-icons/iconfont/material-icons.css'; //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax);

import { defaultAxios, cachedAxios, labsAxios } from './services/http';
Vue.prototype.$http = defaultAxios;
Vue.prototype.$httpLabs = labsAxios;
Vue.prototype.$httpCached = cachedAxios;

// Vuexy Admin Filters
import './filters/filters';

// Clipboard
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

// ACL
import acl from './acl/acl';

// Theme Configurations
import '../themeConfig.js';

// Globally Registered Components
import './globalComponents.js';

// Styles: SCSS
import './assets/scss/main.scss';

//Load Script
import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);

// Loading Skeleton
import Skeleton from 'vue-loading-skeleton';
Vue.use(Skeleton);

// Tailwind
import '@/assets/css/main.css';

// VeeValidate
import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);

// Vue Router
import router from './router';

//Vue Debounce
import vueDebounce from 'vue-debounce';
Vue.use(vueDebounce);

//Vue Transitions
import Transitions from 'vue2-transitions';
Vue.use(Transitions);

// Vuex Store
import store from './store/store';

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer';
Vue.use(VueHammer);

//Vue Movable
import movable from 'v-movable';
Vue.use(movable);

//Vue Circular Countdown Timer
import CircularCountDownTimer from 'vue-circular-count-down-timer';
Vue.use(CircularCountDownTimer);

//Confetti
import VueConfetti from 'vue-confetti';
Vue.use(VueConfetti);

//Fullscreen
import VueFullscreen from 'vue-fullscreen';
Vue.use(VueFullscreen);

//Draggable
import VueShopifyDraggable from 'vue-shopify-draggable';
Vue.use(VueShopifyDraggable);

// CryptoJS
import VueCryptojs from 'vue-cryptojs';
Vue.use(VueCryptojs);

// PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';

// Image Viewer
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 99999999999999999,
  },
});

// Moment
import VueMoment from 'vue-moment';
Vue.use(VueMoment);

// Vue Plyr
import VuePlyr from 'vue-plyr';
Vue.use(VuePlyr);

// Feather font icon
import './assets/css/iconfont.css';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
library.add(fas);
library.add(far);
Vue.component('font-awesome-icon', FontAwesomeIcon);

// Vue Idle Hook
import IdleVue from 'idle-vue';
const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 60000,
});

// cookie support
import VueCookies from 'vue-cookies';
Vue.use(VueCookies, { domain: 'config-bracelet-calculators-happiness.trycloudflare.com' });

// // Websocket
// import VueSocketIO from './io';
// Vue.use(
//   new VueSocketIO({
//     // debug: true,
//     connection: 'http://localhost:5000',
//     options: { transports: ['websocket'] }, //Optional options
//   }),
// );

Vue.config.productionTip = false;
Vue.config.ignoredElements = [/^stream/];

new Vue({
  router,
  store,
  acl,
  render: (h) => h(App),
}).$mount('#app');
