/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations

==========================================================================================*/

import {defaultAxios} from '@/services/http'
export default {
  SET_BEARER (state, sessionToken) {
    defaultAxios.defaults.headers.common['Authorization'] = `Bearer ${sessionToken}`
  }
}
