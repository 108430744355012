/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state

==========================================================================================*/

import navbarSearchAndPinList from '@/layouts/components/navbar/navbarSearchAndPinList';
import themeConfig, { colors } from '@/../themeConfig.js';

// /////////////////////////////////////////////
// Helper
// /////////////////////////////////////////////
let state = {};

try {
  // *From Auth - Data will be received from auth provider
  const userDefaults = {
    id: '',
    legacyId: '',
    firstName: '',
    lastName: '',
    fullName: '',
    phone: '',
    email: '',
    avatar: 'default',
    theme: 'dark',
    status: 'active',
    role: 'user',
  };

  const userInfoLocalStorage = JSON.parse(localStorage.getItem('userInfo')) || {};
  const getUserInfo = () => {
    const userInfo = {};

    // Update property in user
    Object.keys(userDefaults).forEach((key) => {
      // If property is defined in localStorage => Use that
      userInfo[key] = userInfoLocalStorage[key] ? userInfoLocalStorage[key] : userDefaults[key];
    });

    // Include properties from localStorage
    Object.keys(userInfoLocalStorage).forEach((key) => {
      if (userInfo[key] === undefined && userInfoLocalStorage[key] !== null) userInfo[key] = userInfoLocalStorage[key];
    });

    return userInfo;
  };

  const courseDefaults = {
    active: '',
    id: '',
    name: 'No Course Selected',
    description: '',
    status: '',
    thumbnail: '',
    appsEnabled: [],
  };
  const courseInfoLocalStorage = JSON.parse(localStorage.getItem('courseInfo')) || {};
  const getActiveCourse = () => {
    const courseInfo = courseDefaults;

    // Update property in user
    Object.keys(courseDefaults).forEach((key) => {
      // If property is defined in localStorage => Use that
      courseInfo[key] = courseInfoLocalStorage[key] ? courseInfoLocalStorage[key] : courseInfo[key];
    });

    // Include properties from localStorage
    Object.keys(courseInfoLocalStorage).forEach((key) => {
      if (courseInfo[key] === undefined && courseInfoLocalStorage[key] !== null) courseInfo[key] = courseInfoLocalStorage[key];
    });
    return courseInfo;
  };

  // Check if device is touch device
  // This is used to remove perfect scrollbar from touch devices
  // Using Dynamic pages
  const isTouchDevice = () => {
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    const mq = function (query) {
      return window.matchMedia(query).matches;
    };

    if ('ontouchstart' in window || window.DocumentTouch) {
      return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
  };

  // /////////////////////////////////////////////
  // State
  // /////////////////////////////////////////////

  state = {
    AppActiveUser: getUserInfo(),
    AppActiveCourse: getActiveCourse(),
    bodyOverlay: false,
    isVerticalNavMenuActive: true,
    isTouchDevice: isTouchDevice(),
    mainLayoutType: themeConfig.mainLayoutType || 'vertical',
    navbarSearchAndPinList,
    reduceButton: themeConfig.sidebarCollapsed,
    verticalNavMenuWidth: 'default',
    verticalNavMenuItemsMin: false,
    scrollY: 0,
    starredPages: navbarSearchAndPinList['pages'].data.filter((page) => page.is_bookmarked),
    theme: themeConfig.theme || 'dark',
    themePrimaryColor: colors.primary,

    // Can be used to get current window with
    // Note: Above breakpoint state is for internal use of sidebar & navbar component
    windowWidth: null,
  };
} catch (error) {
  localStorage.removeItem('userInfo');
  localStorage.removeItem('courseInfo');
  window.location.href = '/login';
}

export default state;
