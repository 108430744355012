/*=========================================================================================
  File Name: moduleAuthState.js
  Description: Auth Module State

==========================================================================================*/

export default {
  isUserLoggedIn: () => {
    return localStorage.getItem('userInfo') && localStorage.getItem('sessionToken');
  },
};
