import Vue from 'vue';
import { AclCreate, AclInstaller, AclRule } from 'vue-acl';
import router from '@/router';
import store from '@/store/store';

Vue.use(AclInstaller);

let initialRole = 'visitor';

const userInfo = JSON.parse(localStorage.getItem('userInfo'));
if (userInfo && userInfo.role) initialRole = userInfo.role;

export default new AclCreate({
  initial: initialRole || 'visitor',
  notfound: {
    path: '/login',
    forwardQueryParams: true,
  },
  router,
  acceptLocalRules: true,
  globalRules: {
    visitor: new AclRule('visitor').or('user').or('employee').or('proctor').or('console').or('admin').generate(),
    user: new AclRule('user').or('employee').or('proctor').or('console').or('admin').generate(),
    employee: new AclRule('employee').or('proctor').or('console').or('admin').generate(),
    proctor: new AclRule('proctor').or('console').or('admin').generate(),
    console: new AclRule('console').generate(),
    admin: new AclRule('admin').or('console').generate(),
  },
  middleware: async (acl) => {
    acl.change(store.state.AppActiveUser.role);
  },
});
