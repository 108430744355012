// axios
import axios from 'axios';
import cachios from 'cachios';

const defaultAxios = axios.create({
  baseURL: process.env.VUE_APP_ROOT_DEFAULT_API,
  // baseURL: 'https://ip-learn-stg-api.mema.co/v1/',
  // baseURL: 'http://localhost:4000/v1/',
  withCredentials: true,
});

const labsAxios = axios.create({
  baseURL: process.env.VUE_APP_ROOT_LABS_API,
});

const cachedAxios = cachios.create(defaultAxios);

export { defaultAxios, cachedAxios, labsAxios };
