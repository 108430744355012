import { defaultAxios } from '@/services/http';
import router from '@/router';
import Vue from 'vue';
import store from '../store/store.js';

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function addSubscriber(callback) {
  subscribers.push(callback);
}

function onSessionReprotected(data) {
  localStorage.setItem('sessionToken', data.sessionToken);
  localStorage.setItem('protectionKey', data.protectionKey);
  Vue.prototype.$http.defaults.headers.common['Authorization'] = `Bearer ${data.sessionToken}`;
  defaultAxios.defaults.headers.common['Authorization'] = `Bearer ${data.sessionToken}`;
  subscribers = subscribers.filter((callback) => callback(data.sessionToken));
}

export default {
  async init() {
    if (localStorage['sessionToken'] !== undefined) {
      defaultAxios.defaults.headers.common['Authorization'] = `Bearer ${localStorage['sessionToken']}`;
    }

    defaultAxios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const { config, response } = error;
        const originalRequest = config;

        if (response && response.status === 401) {
          if (response.data.type === 'UNAUTHORIZED_ACCESS') {
            return this.logout();
          }
          if (response.data.type === 'SESSION_EXPIRED') {
            if (isAlreadyFetchingAccessToken === false) {
              isAlreadyFetchingAccessToken = true;
              store
                .dispatch('auth/fetchAccessToken')
                .then((response) => {
                  onSessionReprotected(response.data);
                })
                .catch(() => {
                  isAlreadyFetchingAccessToken = false;
                  return this.logout();
                });
            }

            return new Promise((resolve) => {
              addSubscriber((access_token) => {
                originalRequest.headers.Authorization = `Bearer ${access_token}`;
                resolve(defaultAxios(originalRequest));
              });
            });
          }
        }
        return Promise.reject(error);
      },
    );
  },

  createLoginRequest(email, method, turnstileToken) {
    return defaultAxios.post('auth/login/createLoginRequest', { email, method, turnstileToken });
  },
  refreshUserObject() {
    return defaultAxios.get('user/@me');
  },
  refreshCourseObject() {
    return defaultAxios.get('user/@me/activeCourse');
  },
  getLoginMethods(email, turnstileToken) {
    return defaultAxios.post('auth/login/getLoginMethods', { email, turnstileToken });
  },
  loginStandard(loginToken, challengeCode, password, turnstileToken) {
    return defaultAxios.post('auth/login/submitLoginRequest', { loginToken, challengeCode, password, turnstileToken });
  },
  loginMagicLink(magicToken, turnstileToken) {
    return defaultAxios.post('auth/magiclink/useMagicLink', { magicToken, turnstileToken });
  },
  logout(api = false) {
    console.log('Logging Out');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('courseInfo');
    localStorage.removeItem('sessionToken');
    localStorage.removeItem('protectionKey');
    delete defaultAxios.defaults.headers.common['Authorization'];
    router.push('/login');
  },
  registerUser(first_name, last_name, email, password, recaptcha) {
    return defaultAxios.post('user/register', {
      first_name,
      last_name,
      email,
      password,
      recaptcha,
    });
  },
  reprotectSession() {
    return defaultAxios
      .post('auth/session/refreshSession', {
        protectionKey: localStorage.getItem('protectionKey'),
      })
      .catch(() => {
        this.logout();
      });
  },
};
