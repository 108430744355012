/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.

==========================================================================================*/

import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store/store';
import jwt from './services/jwt';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // User Routes
        // =============================================================================
        {
          path: '/',
          redirect: '/dashboard',
        },
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('@/views/student/Dashboard.vue'),
          meta: {
            rule: 'visitor',
            authRequired: true,
            browserTitle: 'Dashboard',
          },
        },
        {
          path: '/apps',
          name: 'apps',
          component: () => import('@/views/platform/apps/launcher/AppLauncher'),
          meta: {
            rule: 'user',
            authRequired: true,
            browserTitle: 'Apps Launcher',
          },
        },
        {
          path: '/courses',
          name: 'courses',
          component: () => import('@/views/platform/courses/selector/CourseSelector.vue'),
          meta: {
            rule: 'user',
            authRequired: true,
            browserTitle: 'Course Selector',
          },
        },
        // =============================================================================
        // App Routes
        // =============================================================================
        {
          path: '/simulations/select',
          name: 'app-simulation-selector',
          component: () => import('@/views/platform/apps/simulations/SimulationSelector.vue'),
          meta: {
            rule: 'user',
            authRequired: true,
            browserTitle: 'Simulation Selector',
          },
        },
        {
          path: '/labs/select',
          name: 'app-labs-selector',
          component: () => import('@/views/platform/apps/labs/LabsSelector.vue'),
          meta: {
            rule: 'user',
            authRequired: true,
            browserTitle: 'Labs Selector',
          },
        },
        // {
        //   path: '/flashcards/select',
        //   name: 'app-flashcards-selector',
        //   component: () => import('@/views/platform/apps/flashcards/FlashCardSelector'),
        //   meta: {
        //     rule: 'user',
        //     authRequired: true,
        //     browserTitle: 'FlashCard Selector',
        //   },
        // },
        {
          path: '/study/select',
          name: 'app-study-selector',
          component: () => import('@/views/platform/apps/flashcards/StudySelector'),
          meta: {
            rule: 'user',
            authRequired: true,
            browserTitle: 'Study Set Selector',
          },
        },
        {
          path: '/examedge/select',
          name: 'app-examedge-selector',
          component: () => import('@/views/platform/apps/examedge/TestPrepSelector'),
          meta: {
            rule: 'user',
            authRequired: true,
            browserTitle: 'ExamEdge Selector',
          },
        },

        // =============================================================================
        // Module Routes
        // =============================================================================
        {
          path: '/simulations/:simulationId/play',
          name: 'app-simulation-play',
          component: () => import('@/views/platform/modules/simulations/legacy/SimulationRenderer.vue'),
          meta: {
            rule: 'user',
            authRequired: true,
            browserTitle: 'Simulation',
            confirmLeave: true,
          },
        },
        {
          path: '/labs/:labId/play',
          name: 'app-labs-play',
          component: () => import('@/views/platform/modules/labs/LabsRenderer.vue'),
          meta: {
            rule: 'user',
            authRequired: true,
            browserTitle: 'Lab',
            confirmLeave: true,
          },
        },
        {
          path: '/study/flashcards/:setId/play',
          name: 'app-study-flashcards-play',
          component: () => import('@/views/platform/modules/flashcards/FlashCardRenderer'),
          meta: {
            rule: 'user',
            authRequired: true,
            browserTitle: 'FlashCards',
            confirmLeave: true,
          },
        },
        {
          path: '/examedge/:quizId/play',
          name: 'app-examedge-play',
          component: () => import('@/views/platform/modules/examedge/TestPrepRenderer'),
          meta: {
            rule: 'user',
            authRequired: true,
            browserTitle: 'ExamEdge',
            confirmLeave: true,
          },
        },

        {
          path: '/study/match/:setId/play',
          name: 'app-study-match-play',
          component: () => import('@/views/platform/modules/match/MatchRenderer.vue'),
          meta: {
            rule: 'user',
            authRequired: true,
            browserTitle: 'Match',
            confirmLeave: true,
          },
        },

        {
          path: '/study/quiz/:setId/play',
          name: 'app-study-learn-play',
          component: () => import('@/views/platform/modules/quiz/QuizRenderer.vue'),
          meta: {
            rule: 'user',
            authRequired: true,
            browserTitle: 'Quiz',
            confirmLeave: true,
          },
        },

        // =============================================================================
        // Instructor Tools Routes
        // =============================================================================
        {
          path: '/a/instructor-tools',
          name: 'instructor-tools',
          component: () => import('@/views/platform/modules/instructor-tools/InstructorToolsRenderer.vue'),
          meta: {
            breadcrumb: [{ title: 'Home', url: '/' }, { title: 'Proctor' }, { title: 'Instructor Tools', active: true }],
            rule: 'proctor',
            pageTitle: 'Instructor Tools',
            authRequired: true,
          },
        },

        {
          path: '/a/instructor-tools/class-timer',
          name: 'instructor-tools-class-timer',
          component: () => import('@/views/platform/modules/instructor-tools/tools/ClassTimer.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Instructor Tools', url: '/a/instructor-tools' },
              { title: 'Class Timer', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'Class Timer',
            authRequired: true,
          },
        },

        {
          path: '/a/instructor-tools/class-timer',
          name: 'instructor-tools-class-timer',
          component: () => import('@/views/platform/modules/instructor-tools/tools/ClassTimer.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Instructor Tools', url: '/a/instructor-tools' },
              { title: 'Class Timer', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'Class Timer',
            authRequired: true,
          },
        },

        // =============================================================================
        // Course Management Routes
        // =============================================================================
        {
          path: '/a/courses',
          name: 'admin-course-classroom-list-courses',
          component: () => import('@/views/platform/courses/admin/course-list/CourseList.vue'),
          meta: {
            breadcrumb: [{ title: 'Home', url: '/' }, { title: 'Proctor' }, { title: 'Courses', active: true }],
            rule: 'proctor',
            pageTitle: 'Courses List',
            authRequired: true,
          },
        },
        {
          path: '/a/courses/:courseId',
          name: 'admin-course-view-courses',
          component: () => import('@/views/platform/courses/admin/course-view/CourseView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Courses', url: '/a/courses' },
              { title: 'View', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'View Course',
            authRequired: true,
          },
        },
        {
          path: '/a/courses/:courseId/mass',
          name: 'admin-course-view-courses',
          component: () => import('@/views/platform/courses/admin/course-view/CourseViewMassProgress.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Courses', url: '/a/courses' },
              { title: 'Mass Progress', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'Mass Progress',
            authRequired: true,
          },
        },
        // =============================================================================
        // Classroom Management Routes
        // =============================================================================
        {
          path: '/a/classrooms',
          name: 'admin-course-classroom-list-classrooms',
          component: () => import('@/views/platform/classrooms/admin/classroom-list/ClassroomList.vue'),
          meta: {
            breadcrumb: [{ title: 'Home', url: '/' }, { title: 'Proctor' }, { title: 'Classrooms', active: true }],
            rule: 'proctor',
            pageTitle: 'Classrooms List',
            authRequired: true,
          },
        },
        {
          path: '/a/classrooms/:classroomId',
          name: 'admin-course-view-classrooms',
          component: () => import('@/views/platform/classrooms/admin/classroom-view/ClassroomView.vue'),
          meta: {
            rule: 'proctor',
            authRequired: true,
          },
        },
        // =============================================================================
        // User Management Routes
        // =============================================================================
        {
          path: '/a/users',
          name: 'admin-list-user',
          component: () => import('@/views/student/users/admin/user-list/ListUser'),
          meta: {
            breadcrumb: [{ title: 'Home', url: '/' }, { title: 'Proctor' }, { title: 'Users', active: true }],
            rule: 'proctor',
            pageTitle: 'User List',
            authRequired: true,
          },
        },
        {
          path: '/a/users/upload',
          name: 'admin-upload-user',
          component: () => import('@/views/student/users/admin/user-create/UploadUsers'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Users', url: '/a/users' },
              { title: 'Upload', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'Upload Users',
            authRequired: true,
          },
        },
        {
          path: '/a/users/:userId',
          name: 'admin-view-user',
          component: () => import('@/views/student/users/admin/user-view/UserView'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Users', url: '/a/users' },
              { title: 'View', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'View User',
            authRequired: true,
          },
        },
        {
          path: '/a/users/:userId/progress/:courseId',
          name: 'admin-view-user-course-progress',
          component: () => import('@/views/student/users/admin/user-view/UserViewCourseProgress'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Users', url: '/a/users' },
              { title: 'View Course Progress', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'View User Course Progress',
            authRequired: true,
          },
        },
        // =============================================================================
        // Simulation Management Routes
        // =============================================================================
        {
          path: '/a/simulations',
          name: 'admin-list-simulation',
          component: () => import('@/views/platform/modules/simulations/admin/simulation-list/ListSimulations.vue'),
          meta: {
            breadcrumb: [{ title: 'Home', url: '/' }, { title: 'Proctor' }, { title: 'Simulations', active: true }],
            rule: 'proctor',
            pageTitle: 'Simulations List',
          },
        },
        {
          path: '/a/simulations/:simulationId',
          name: 'admin-view-simulation',
          component: () => import('@/views/platform/modules/simulations/admin/simulation-view/SimulationView'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Simulations', url: '/a/simulations' },
              { title: 'View', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'View Simulation',
            authRequired: true,
          },
        },
        {
          path: '/a/simulation/create',
          name: 'admin-create-simulation',
          component: () => import('@/views/platform/modules/simulations/admin/simulation-create/CreateSimulation.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Simulations', url: '/a/simulations' },
              { title: 'Create', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'Create Simulation',
            authRequired: true,
          },
        },
        // =============================================================================
        // Lab Management Routes
        // =============================================================================
        {
          path: '/a/labs',
          name: 'admin-lab',
          component: () => import('@/views/platform/modules/labs/admin/LabAdmin.vue'),
          meta: {
            breadcrumb: [{ title: 'Home', url: '/' }, { title: 'Proctor' }, { title: 'Labs', active: true }],
            rule: 'admin',
            pageTitle: 'Labs Admin',
          },
        },
        {
          path: '/a/labs/properties/list',
          name: 'admin-list-lab',
          component: () => import('@/views/platform/modules/labs/admin/properties/lab-list/ListLabs.vue'),
          meta: {
            breadcrumb: [{ title: 'Home', url: '/' }, { title: 'Proctor' }, { title: 'Labs' }, { title: 'List', active: true }],
            rule: 'admin',
            pageTitle: 'Labs List',
          },
        },
        {
          path: '/a/labs/properties/:labId',
          name: 'admin-edit-lab',
          component: () => import('@/views/platform/modules/labs/admin/properties/lab-edit/EditLab.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Labs', url: '/a/labs' },
              { title: 'Edit', active: true },
            ],
            rule: 'admin',
            pageTitle: 'Edit Lab',
            authRequired: true,
          },
        },
        {
          path: '/a/labs/accounts/list',
          name: 'admin-list-lab-accounts',
          component: () => import('@/views/platform/modules/labs/admin/accounts/accounts-list/ListAccounts.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Labs' },
              { title: 'Accounts' },
              { title: 'List', active: true },
            ],
            rule: 'admin',
            pageTitle: 'Lab Accounts List',
          },
        },
        {
          path: '/a/labs/accounts/new',
          name: 'admin-create-lab-accounts',
          component: () => import('@/views/platform/modules/labs/admin/accounts/accounts-create/CreateAccount.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Labs', url: '/a/labs' },
              { title: 'Accounts' },
              { title: 'Create', active: true },
            ],
            rule: 'admin',
            pageTitle: 'Create Lab Account',
            authRequired: true,
          },
        },
        {
          path: '/a/labs/accounts/:labId',
          name: 'admin-edit-lab-accounts',
          component: () => import('@/views/platform/modules/labs/admin/accounts/accounts-edit/EditAccount.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Labs', url: '/a/labs' },
              { title: 'Accounts' },
              { title: 'Edit', active: true },
            ],
            rule: 'admin',
            pageTitle: 'Edit Lab Account',
            authRequired: true,
          },
        },
        {
          path: '/a/labs/pools/list',
          name: 'admin-list-lab-pools',
          component: () => import('@/views/platform/modules/labs/admin/pools/pools-list/ListPools.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Labs' },
              { title: 'Pools' },
              { title: 'List', active: true },
            ],
            rule: 'admin',
            pageTitle: 'Lab Pools List',
          },
        },
        {
          path: '/a/labs/pools/new',
          name: 'admin-create-lab-pools',
          component: () => import('@/views/platform/modules/labs/admin/pools/pools-create/CreatePool.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Labs', url: '/a/labs' },
              { title: 'Pools' },
              { title: 'Create', active: true },
            ],
            rule: 'admin',
            pageTitle: 'Create Lab Pool',
            authRequired: true,
          },
        },
        {
          path: '/a/labs/pools/:labId',
          name: 'admin-edit-lab-pools',
          component: () => import('@/views/platform/modules/labs/admin/pools/pools-edit/EditPool.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Labs', url: '/a/labs' },
              { title: 'Pools' },
              { title: 'Edit', active: true },
            ],
            rule: 'admin',
            pageTitle: 'Edit Lab Pool',
            authRequired: true,
          },
        },
        // =============================================================================
        // FlashCard Management Routes
        // =============================================================================
        {
          path: '/a/study',
          name: 'admin-flashcard',
          component: () => import('@/views/platform/modules/flashcards/admin/FlashCardAdmin.vue'),
          meta: {
            breadcrumb: [{ title: 'Home', url: '/' }, { title: 'Proctor' }, { title: 'Study', active: true }],
            rule: 'proctor',
            pageTitle: 'Study Admin',
          },
        },
        {
          path: '/a/study/domains',
          name: 'admin-flashcard-list-domain',
          component: () => import('@/views/platform/modules/flashcards/admin/domains/domain-list/ListDomains.vue'),
          meta: {
            breadcrumb: [{ title: 'Home', url: '/' }, { title: 'Proctor' }, { title: 'Study' }, { title: 'Domains', active: true }],
            rule: 'proctor',
            pageTitle: 'Domains List',
          },
        },
        {
          path: '/a/study/domains/new',
          name: 'admin-flashcard-create-domain',
          component: () => import('@/views/platform/modules/flashcards/admin/domains/domain-create/CreateDomain'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Study' },
              { title: 'Domains', url: '/a/study/domains' },
              { title: 'Create', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'Create Domain',
            authRequired: true,
          },
        },
        {
          path: '/a/study/domains/:domainId',
          name: 'admin-flashcard-view-domain',
          component: () => import('@/views/platform/modules/flashcards/admin/domains/domain-view/DomainView'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Study' },
              { title: 'Domains', url: '/a/study/domains' },
              { title: 'View', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'View Domain',
            authRequired: true,
          },
        },
        {
          path: '/a/study/domains/:domainId/edit',
          name: 'admin-flashcard-edit-domain',
          component: () => import('@/views/platform/modules/flashcards/admin/domains/domain-edit/EditDomain.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Study' },
              { title: 'Domains', url: '/a/study/domains' },
              { title: 'Edit', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'Edit Domain',
            authRequired: true,
          },
        },
        {
          path: '/a/study/domains/:domainId/builder',
          name: 'admin-flashcard-build-domain',
          component: () => import('@/views/platform/modules/flashcards/admin/domains/domain-edit/BuildDomain'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Study' },
              { title: 'Domains', url: '/a/study/domains' },
              { title: 'Build', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'Build Domain',
            authRequired: true,
          },
        },
        {
          path: '/a/study/sets',
          name: 'admin-flashcard-list-set',
          component: () => import('@/views/platform/modules/flashcards/admin/sets/set-list/ListSets.vue'),
          meta: {
            breadcrumb: [{ title: 'Home', url: '/' }, { title: 'Proctor' }, { title: 'Study' }, { title: 'Sets', active: true }],
            rule: 'proctor',
            pageTitle: 'Sets List',
            authRequired: true,
          },
        },
        {
          path: '/a/study/sets/new',
          name: 'admin-flashcard-create-set',
          component: () => import('@/views/platform/modules/flashcards/admin/sets/set-create/CreateSet.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Study' },
              { title: 'Sets', url: '/a/study/sets' },
              { title: 'New', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'New Set',
            authRequired: true,
          },
        },
        {
          path: '/a/study/sets/:setId',
          name: 'admin-flashcard-view-set',
          component: () => import('@/views/platform/modules/flashcards/admin/sets/set-view/SetView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Study' },
              { title: 'Sets', url: '/a/study/sets' },
              { title: 'View', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'View Set',
            authRequired: true,
          },
        },
        {
          path: '/a/study/sets/:setId/edit',
          name: 'admin-flashcard-edit-set',
          component: () => import('@/views/platform/modules/flashcards/admin/sets/set-edit/EditSet.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Study' },
              { title: 'Sets', url: '/a/study/sets' },
              { title: 'Edit', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'Edit Set',
            authRequired: true,
          },
        },
        {
          path: '/a/study/sets/:setId/builder',
          name: 'admin-flashcard-build-set',
          component: () => import('@/views/platform/modules/flashcards/admin/sets/set-edit/BuildSet'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Study' },
              { title: 'Sets', url: '/a/study/sets' },
              { title: 'Build', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'Build Set',
            authRequired: true,
          },
        },
        {
          path: '/a/study/questions',
          name: 'admin-flashcard-list-term',
          component: () => import('@/views/platform/modules/flashcards/admin/terms/term-list/ListTerms.vue'),
          meta: {
            breadcrumb: [{ title: 'Home', url: '/' }, { title: 'Proctor' }, { title: 'Study' }, { title: 'Terms', active: true }],
            rule: 'proctor',
            pageTitle: 'Terms Bank',
            authRequired: true,
          },
        },
        {
          path: '/a/study/questions/upload',
          name: 'admin-flashcard-upload-term',
          component: () => import('@/views/platform/modules/flashcards/admin/terms/term-create/UploadTerms'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Study' },
              { title: 'Terms', url: '/a/study/terms' },
              { title: 'Upload', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'Upload Terms',
            authRequired: true,
          },
        },
        {
          path: '/a/study/questions/:termId/edit',
          name: 'admin-flashcard-edit-term',
          component: () => import('@/views/platform/modules/flashcards/admin/terms/term-edit/EditTerm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Study' },
              { title: 'Terms', url: '/a/study/terms' },
              { title: 'Edit', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'Edit Term',
            authRequired: true,
          },
        },
        // =============================================================================
        // TestPrep Management Routes
        // =============================================================================
        {
          path: '/a/examedge',
          name: 'admin-examedge',
          redirect: () => {
            return { path: '/a/examedge/banks' };
          },
          meta: {
            breadcrumb: [{ title: 'Home', url: '/' }, { title: 'Proctor' }, { title: 'Domains', active: true }],
            rule: 'proctor',
            pageTitle: 'Domains List',
          },
        },
        {
          path: '/a/examedge/banks',
          name: 'admin-examedge-list-bank',
          component: () => import('@/views/platform/modules/examedge/admin/banks/bank-list/ListBanks.vue'),
          meta: {
            breadcrumb: [{ title: 'Home', url: '/' }, { title: 'Proctor' }, { title: 'Banks', active: true }],
            rule: 'proctor',
            pageTitle: 'Banks List',
            authRequired: true,
          },
        },
        {
          path: '/a/examedge/banks/new',
          name: 'admin-examedge-create-bank',
          component: () => import('@/views/platform/modules/examedge/admin/banks/bank-create/CreateBank.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Banks', url: '/a/examedge/banks' },
              { title: 'New', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'New Bank',
            authRequired: true,
          },
        },
        {
          path: '/a/examedge/banks/:bankId',
          name: 'admin-examedge-view-bank',
          component: () => import('@/views/platform/modules/examedge/admin/banks/bank-view/BankView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Banks', url: '/a/examedge/banks' },
              { title: 'View', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'View Bank',
            authRequired: true,
          },
        },
        {
          path: '/a/examedge/banks/:bankId/edit',
          name: 'admin-examedge-edit-bank',
          component: () => import('@/views/platform/modules/examedge/admin/banks/bank-edit/EditBank.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Banks', url: '/a/examedge/banks' },
              { title: 'Edit', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'Edit Bank',
            authRequired: true,
          },
        },

        {
          path: '/a/examedge/assignments',
          name: 'admin-examedge-list-assignment',
          component: () => import('@/views/platform/modules/examedge/admin/assignments/assignment-list/ListAssignments.vue'),
          meta: {
            breadcrumb: [{ title: 'Home', url: '/' }, { title: 'Proctor' }, { title: 'Assignments', active: true }],
            rule: 'proctor',
            pageTitle: 'Assignments List',
            authRequired: true,
          },
        },
        {
          path: '/a/examedge/assignments/new',
          name: 'admin-examedge-create-assignment',
          component: () => import('@/views/platform/modules/examedge/admin/assignments/assignment-create/CreateAssignment.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Assignments', url: '/a/examedge/assignments' },
              { title: 'New', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'New Assignment',
            authRequired: true,
          },
        },
        {
          path: '/a/examedge/assignments/:assignmentId',
          name: 'admin-examedge-view-assignment',
          component: () => import('@/views/platform/modules/examedge/admin/assignments/assignment-view/AssignmentView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Assignments', url: '/a/examedge/assignments' },
              { title: 'View', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'View Assignment',
            authRequired: true,
          },
        },
        {
          path: '/a/examedge/assignments/:assignmentId/edit',
          name: 'admin-examedge-edit-assignment',
          component: () => import('@/views/platform/modules/examedge/admin/assignments/assignment-edit/EditAssignment.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Assignments', url: '/a/examedge/assignments' },
              { title: 'Edit', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'Edit Assignment',
            authRequired: true,
          },
        },

        {
          path: '/a/examedge/questions',
          name: 'admin-examedge-list-question',
          component: () => import('@/views/platform/modules/examedge/admin/questions/question-list/ListQuestions.vue'),
          meta: {
            breadcrumb: [{ title: 'Home', url: '/' }, { title: 'Proctor' }, { title: 'Questions', active: true }],
            rule: 'proctor',
            pageTitle: 'Questions Bank',
            authRequired: true,
          },
        },
        {
          path: '/a/examedge/questions/upload',
          name: 'admin-examedge-upload-question',
          component: () => import('@/views/platform/modules/examedge/admin/questions/question-create/UploadQuestions'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Banks', url: '/a/examedge/banks' },
              { title: 'Questions Upload', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'Upload Questions',
            authRequired: true,
          },
        },
        {
          path: '/a/examedge/questions/:questionId/edit',
          name: 'admin-examedge-edit-question',
          component: () => import('@/views/platform/modules/examedge/admin/questions/question-edit/EditQuestion.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Proctor' },
              { title: 'Banks', url: '/a/examedge/banks' },
              { title: 'Question Edit', active: true },
            ],
            rule: 'proctor',
            pageTitle: 'Edit Question',
            authRequired: true,
          },
        },
        // =============================================================================
        // Subscription Management Routes
        // =============================================================================
        {
          path: '/a/subscriptions',
          name: 'admin-list-subscription',
          component: () => import('@/views/student/subscriptions/admin/subscription-list/ListSubscriptions'),
          meta: {
            breadcrumb: [{ title: 'Home', url: '/' }, { title: 'Management' }, { title: 'Subscriptions', active: true }],
            rule: 'admin',
            pageTitle: 'Subscriptions List',
            authRequired: true,
          },
        },
        {
          path: '/a/subscriptions/:subscriptionsId',
          name: 'admin-view-subscription',
          component: () => import('@/views/student/subscriptions/admin/subscription-list/ListSubscriptions'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Management' },
              { title: 'Subscriptions', url: '/a/subscriptions' },
              { title: 'View', active: true },
            ],
            rule: 'admin',
            pageTitle: 'View Subscription',
            authRequired: false,
          },
        },
      ],
    },

    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/utils/Login.vue'),
          meta: {
            rule: 'visitor',
            authRequired: false,
          },
        },
        {
          path: '/404',
          name: 'error-404',
          meta: {
            rule: 'visitor',
            authRequired: true,
          },
          component: () => import('@/views/utils/Error404.vue'),
        },
        {
          path: '/500',
          name: 'error-500',
          meta: {
            rule: 'visitor',
            authRequired: false,
          },
          component: () => import('@/views/utils/Error500.vue'),
        },
      ],
    },
    {
      path: '*',
      redirect: '/404',
    },
  ],
});

router.beforeEach((to, from, next) => {
  Vue.nextTick(() => {
    let title = 'Intellectual Point';
    if (to.meta.pageTitle) title = to.meta.pageTitle;
    if (to.meta.browserTitle) title = to.meta.browserTitle;
    document.title = `${title} | Learn`;
  });

  if (to.meta.authRequired) {
    if (localStorage['sessionToken'] === undefined) {
      console.log('jew1');
    }
    if (!localStorage.getItem('userInfo')) {
      return jwt.logout();
    }
  }

  if (to.query.refresh) {
    // You can perform any necessary actions to refresh the current route
    // For example, you can fetch data or reset the component's state
    // Then remove the query parameter to prevent the route from endlessly refreshing
    next({ ...to, query: null });
  } else {
    next();
  }

  return next();
});

router.afterEach((to) => {
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
  if (!to.path.includes('/login')) {
    store.dispatch('auth/refreshUserObject').catch(() => {});
    store.dispatch('auth/refreshCourseObject').catch(() => {});
  }
});

router.afterEach(() => {
  try {
    document.querySelectorAll('body > div.vs-component').forEach((el) => {
      if (!el.classList.contains('vs-notifications')) {
        el.remove();
      }
    });
    document.querySelectorAll('body > div.vs-content-sidebar').forEach((el) => {
      el.remove();
    });
  } catch (e) {
    // error
  }
});

export default router;
