/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions

==========================================================================================*/

import jwt from '../../services/jwt';
import router from '@/router';

export default {
  refreshUserObject({ commit }) {
    return new Promise((resolve, reject) => {
      jwt
        .refreshUserObject()
        .then((response) => {
          if (response.data) {
            commit('UPDATE_USER_INFO', response.data, { root: true });

            return resolve(response.data);
          }

          reject(response.data);
        })
        .catch((exception) => reject(exception));
    });
  },
  refreshCourseObject({ commit }) {
    return new Promise((resolve, reject) => {
      jwt
        .refreshCourseObject()
        .then((response) => {
          if (response.data) {
            let courseInfo = response.data;

            if (response.data === 'none') {
              localStorage.removeItem('courseInfo');
              courseInfo = null;
            }

            if (response.data !== 'none') {
              courseInfo.selected = true;
              courseInfo.active = true;
            }

            commit('UPDATE_COURSE_INFO', courseInfo, { root: true });

            return resolve(response.data);
          }

          reject(response.data);
        })
        .catch((exception) => reject(exception));
    });
  },
  // JWT
  loginStandard({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .loginStandard(payload.loginToken, payload.challengeCode, payload.password, payload.turnstileToken)
        .then((response) => {
          // If there's user data in response
          if (response.data) {
            const data = response.data;

            // Set accessToken
            localStorage.setItem('sessionToken', data.sessionToken);
            localStorage.setItem('protectionKey', data.protectionKey);

            // Update user details
            commit('UPDATE_USER_INFO', data.user, { root: true });

            // Set bearer token in axios
            commit('SET_BEARER', data.sessionToken);

            // Go To Dashboard
            router.push('/dashboard');

            resolve(response);
          } else {
            reject({ message: 'Wrong Email or Password' });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // Magic Link
  loginMagicLink({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .loginMagicLink(payload.magicToken, payload.turnstileToken)
        .then((response) => {
          // If there's user data in response
          if (response.data) {
            const data = response.data;

            // Set accessToken
            localStorage.setItem('sessionToken', data.sessionToken);
            localStorage.setItem('protectionKey', data.protectionKey);

            // Update user details
            commit('UPDATE_USER_INFO', data.user, { root: true });

            // Set bearer token in axios
            commit('SET_BEARER', data.sessionToken);

            // Go To Dashboard
            router.push('/dashboard');

            resolve(response);
          } else {
            reject({ message: 'Unable to Automatically Login' });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAccessToken() {
    return new Promise((resolve) => {
      jwt
        .reprotectSession()
        .then((response) => {
          resolve(response);
        })
        .catch((exception) => {
          throw exception;
        });
    });
  },
};
